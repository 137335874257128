import '../../utils/marketingPageDataFragment';

import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { AnimotoLayout } from '../../components/AnimotoLayout';
import LegalCopyBlockModule, { LegalCopyBlockDataPropsObject } from '../../components/LegalCopyBlockModule';
import LegalSidebarMenuModule, { LegalSidebarMenuDataPropsObject } from '../../components/LegalSidebarMenuModule';
import componentsQueryResultToData from '../../utils/componentsQueryResultToData';
import {
  legalCopy,
  legalSidebar,
  wrap
} from './MarketingLegalPage.module.css';

export default function MarketingLegalPage({ data }) {
  const { page } = data;
  const {
    canonicalUrlPage,
    components,
    description,
    imageFieldName,
    showSiteNav
  } = page;
  const SEOInfo = {
    title         : page.title,
    description   : description && description.description,
    slug          : page.slug,
    canonicalSlug : canonicalUrlPage && canonicalUrlPage.slug,
    noIndex       : page.noIndex
  };

  const {
    legalCopyBlock,
    legalCopyBlockContentKey,
    legalSidebarMenu,
    legalSidebarMenuContentKey
  } = componentsQueryResultToData(components);

  if (imageFieldName && imageFieldName.file && imageFieldName.file.url) {
    SEOInfo.image = imageFieldName.file.url;
  }

  return (
    <AnimotoLayout SEOInfo={SEOInfo} showSiteNav={showSiteNav}>
      <div className={wrap}>
        <LegalCopyBlockModule className={legalCopy} contentKey={legalCopyBlockContentKey} data={legalCopyBlock} />
        <LegalSidebarMenuModule className={legalSidebar} contentKey={legalSidebarMenuContentKey} data={legalSidebarMenu} />
      </div>
    </AnimotoLayout>
  );
}

export const pageQuery = graphql`
  query MarketingLegalPage($slug: String!) {
    page : contentfulMarketingPage(slug: { eq: $slug }) {
      ...MarketingPageData
    }
  }
`;

MarketingLegalPage.propTypes = {
  data : PropTypes.shape({
    page : PropTypes.shape({
      canonicalUrlPage : PropTypes.shape({
        slug : PropTypes.string
      }),
      components : PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentLegalCopyBlock']),
          ...LegalCopyBlockDataPropsObject
        }),
        PropTypes.shape({
          __typename : PropTypes.oneOf(['ContentfulComponentLegalSidebarMenu']),
          ...LegalSidebarMenuDataPropsObject
        })
      ])).isRequired,
      description : PropTypes.shape({
        description : PropTypes.string
      }),
      imageFieldName : PropTypes.shape({
        file : PropTypes.shape({
          url : PropTypes.string
        })
      }),
      noIndex     : PropTypes.bool,
      showSiteNav : PropTypes.bool,
      slug        : PropTypes.string.isRequired,
      title       : PropTypes.string.isRequired,
      type        : PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};
