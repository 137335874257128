// extracted by mini-css-extract-plugin
export var base = "MarketingLegalPage-module--base--1cUNl";
export var rightAlignedContainer = "MarketingLegalPage-module--rightAlignedContainer--2x-VT";
export var rightAlignedContainerSmall = "MarketingLegalPage-module--rightAlignedContainerSmall--yImSM";
export var leftAlignedContainer = "MarketingLegalPage-module--leftAlignedContainer--3XVrM";
export var blogContainer = "MarketingLegalPage-module--blogContainer--1-2ug MarketingLegalPage-module--base--1cUNl";
export var container = "MarketingLegalPage-module--container--cs05q MarketingLegalPage-module--base--1cUNl";
export var largeContainer = "MarketingLegalPage-module--largeContainer--NzgZx MarketingLegalPage-module--base--1cUNl";
export var mobileSmallPadding = "MarketingLegalPage-module--mobileSmallPadding--2Acr4";
export var fullWidthMobile = "MarketingLegalPage-module--fullWidthMobile--1dLsJ";
export var gridWithSidebar = "MarketingLegalPage-module--gridWithSidebar--S-yyb";
export var noMaxWidth = "MarketingLegalPage-module--noMaxWidth--1gFP0";
export var wrap = "MarketingLegalPage-module--wrap--FSQ7F MarketingLegalPage-module--gridWithSidebar--S-yyb";
export var legalCopy = "MarketingLegalPage-module--legalCopy--7xBdK";
export var legalSidebar = "MarketingLegalPage-module--legalSidebar--1FjQp";